import React from 'react'
import { Script } from "gatsby"
import { StaticImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'

import Grid from '../components/elements/grid'
import Icon from "../components/elements/icon"
import Layout from '../layouts'
import { SizedText, Canon, Body } from '../components/elements/typography'
import LargeHero from '../components/elements/largeHero'
import SEO from '../components/elements/seo'
import scrollTo from 'gatsby-plugin-smoothscroll'

import rightArrowIcon from "../theme/icons/right-arrow.svg"
import linkIcon from "../theme/icons/link.svg"
import ReviewSection from '../components/sections/reviewSection'
import ExternalLink from '../components/elements/externalLink'
import ChatButton from '../components/elements/chatButton'



// markup
const DomainSecurity = () => {
  return (
    <Layout isSubMenuOpen={false}>

      { /* ------ * HERO SECTION * ------ */}
      <LargeHero.Container
                background={{
                  backgroundImage: `radial-gradient( circle farthest-corner at 10% 20%,  #3aacd0 0%, rgba(0,84,166,1) 90% )`,
                  borderBottom: `1px solid #3e769d`,
                  padding: `0 0 2rem 0`,
                }}
      >
        <LargeHero.Content styles={{ }}>
          <h1 css={{fontFamily: `Proxima-nova`, textTransform: `uppercase`, 'span': {display: `block`, lineHeight: `1`, padding: `0.25rem 0`, color: `#e4f0f4`}}}>
            <SizedText as="span" smallSize="2.887" largeSize="4.624">.BANK DOMAIN FAQS</SizedText>
          </h1>
          <SizedText as="p" smallSize="1" largeSize="1.424" css={{padding: `1rem 0`, lineHeight: '1.5 !important', color: `#e4f0f4`, '@media (min-width: 1300px)': { padding: '1rem' } }}>
          For security purposes our email addresses and website URL now end in '.bank' instead of '.com'; look for the '.bank' before interacting with any email or website of ours.
          </SizedText>
        </LargeHero.Content>
        <LargeHero.Image>
        <StaticImage 
            css={{
              display: `block !important`,  
              '@media (min-width: 680px)': { display: 'none !important' },
              '@media (min-width: 1300px)': { display: 'block !important' } 
            }} 
            src="../images/cyber-security.png" 
            alt="" 
          />
          <StaticImage 
            css={{
              display: `none !important`,  
              '@media (min-width: 680px)': { display: 'block !important' },
              '@media (min-width: 1300px)': { display: 'none !important' } 
            }} 
            src="../images/cyber-security_cropped.png" 
            alt="" 
          />
        </LargeHero.Image>
      </LargeHero.Container>
<Grid.FullWidthSection css={{paddingBottom: `3rem`, '@media (min-width: 1024px)': { paddingBottom: `6rem` } }}>
      <Grid.Block start={[2,2,2,2,2,2,3]} span={[12,12,12,12,8,8,7]} css={{
          padding: `4rem 0 0 0`,
          'p': {textAlign: `justify`, color: "#707070"},
          'h2': { marginTop: `2rem`, color: `#2980b9`, fontFamily: `proxima-nova`}
        }}>

            <SizedText as="h2" smallSize="1.424" largeSize="1.802" css={{paddingBottom: `1rem`}}>
              What is .BANK?
            </SizedText>
            <Body>
              .BANK is a gated domain, like .gov or .edu, but for verified banks. Replacing .com, which can be purchased by anyone, .BANK quickly verifies that the website or email is authentically from our bank, 
              so you can interact with confidence when you see the ‘.BANK’ at the end of our email address and website URL.
            </Body>

            <SizedText as="h2" smallSize="1.424" largeSize="1.802" css={{paddingBottom: `1rem`}}>
              How is .BANK more secure?
            </SizedText>
            <Body>
              All banks are verified and authenticated by <ExternalLink to="https://www.ftld.com/">fTLD</ExternalLink>, the .BANK administrator, prior to registering their .BANK domain, and re-verified annually thereafter. This ensures everyone using a .BANK domain is  
              an <ExternalLink to="https://www.register.bank/eligibility/">eligible organization</ExternalLink>. Hackers and bad actors can’t get a .BANK domain to create lookalike domains for phishing and spoofing, as they can in ‘.com’ and other publicly available domains.
              <br /><br />
              With the ‘.BANK’ visual authentication cue in place you can quickly confirm emails and websites of ours are real, and avoid interactions that could lead to identity theft and financial fraud. This authentication 
              is also an additional layer of protection for our internal and vendor communications, helping us to further secure against potential breaches.
              <br /><br />
              All banks within the .BANK domain must also implement additional <ExternalLink to="https://www.register.bank/security/">Security Requirements</ExternalLink> to help secure their sites and email, and protect them, their vendors and their customers from phishing, spoofing and other 
              cyberattacks. All banks using .BANK are monitored for compliance with these security requirements on an ongoing basis.
            </Body>

            <SizedText as="h2" smallSize="1.424" largeSize="1.802" css={{paddingBottom: `1rem`}}>
              Do I need to do anything differently now that you’re in .BANK?
            </SizedText>
            <Body>
              Our email addresses and website URL now end in .BANK. From now on, before interacting with emails from us, and before you enter your username and password on our website, simply look for the ‘.BANK’ to authenticate the email or website is ours.
              <br /><br />
              Our .com email addresses are forwarding to our new .BANK email addresses, and our .com website redirects to our new .BANK site, but over time you should update your address book and bookmark for our site.
            </Body>

            <SizedText as="h2" smallSize="1.424" largeSize="1.802" css={{paddingBottom: `1rem`}}>
              You’re the first ‘.BANK’ I’ve seen, why haven’t all the other banks moved?
            </SizedText>
            <Body>
            The move to .BANK is a business decision every bank must make, and plan for, based on its own priorities and resources. We decided it was a priority for us to enhance our security and provide our customers with an easy way to authenticate our email communications and website so we’ve made the move to .BANK.
            </Body>
        </Grid.Block>

        <Grid.Block start={[2,2,2,2,10,10]} span={[12,12,12,12,4,3]}
          css={{ 
            margin: `6rem 0`,
        }}>
          <div css={{
                        borderTop: `5px solid #d7d7d7`,
                        borderBottom: `5px solid #d7d7d7`,
                        padding: `2rem 2rem 4rem 2rem`,
          }}>
            <SizedText as="h2" smallSize="1.602" largeSize="2.027" styleOverrides={{ fontFamily: `source-sans-pro !important`, fontWeight: `300 !important`, marginBottom: `3rem`}}>Have specific questions about our new domain?</SizedText>
            <SizedText as="p" smallSize="1" largeSize="1.266" styleOverrides={{ fontFamily: `source-sans-pro !important`, fontWeight: `300 !important` }}>Troy A. Poulin</SizedText>
            <Body><a href="mailto:tpoulin@tcbga.bank">tpoulin@tcbga.bank</a></Body>
          </div>
            

        </Grid.Block>
      </Grid.FullWidthSection>


    </Layout>
  )
}

export default DomainSecurity